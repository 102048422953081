<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col
      cols="12"
      class="col-16 pl-sm-32 py-24 py-sm-32 overflow-hidden"
    >
      <h2>Edit Vendor</h2>
    </b-col>
    <b-col class="col-6">
      <ul>
        <li>
          <div class="d-block mb-3">
            <label>Nama Vendor</label>
            <b-form-input
              v-model="vendorData.nama"
              type="text"
              :state="v$.vendorData.nama.$dirty ? !v$.vendorData.nama.$error : null"
              placeholder="Masukkan Nama Vendor"
            />
            <b-form-invalid-feedback :state="v$.vendorData.nama.$dirty ? !v$.vendorData.nama.$error : null">
              {{ errorMessages.nama }}
            </b-form-invalid-feedback>
          </div>
        </li>
        <li>
          <div class="d-block mb-3">
            <label>Nomor Telepon</label>
            <b-form-input
              v-model="vendorData.telp"
              type="number"
              :state="v$.vendorData.telp.$dirty ? !v$.vendorData.telp.$error : null"
              placeholder="Masukkan Nomor Telepon"
            />
            <b-form-invalid-feedback :state="v$.vendorData.telp.$dirty ? !v$.vendorData.telp.$error : null">
              {{ errorMessages.telp }}
            </b-form-invalid-feedback>
          </div>
        </li>
        <li>
          <div class="d-block mb-3">
            <label>Alamat Email Vendor</label>
            <b-form-input
              v-model="vendorData.email"
              :state="v$.vendorData.email.$dirty ? !v$.vendorData.email.$error : null"
              type="email"
              placeholder="Masukkan Email Vendor"
            />
            <b-form-invalid-feedback :state="v$.vendorData.email.$dirty ? !v$.vendorData.email.$error : null">
              {{ errorMessages.email }}
            </b-form-invalid-feedback>
          </div>
        </li>
        <li>
          <div class="d-block mb-3">
            <label>PIC</label>
            <b-form-input
              v-model="vendorData.pic"
              type="text"
              :state="v$.vendorData.pic.$dirty ? !v$.vendorData.pic.$error : null"
              placeholder="Masukkan Nama PIC"
            />
            <b-form-invalid-feedback :state="v$.vendorData.pic.$dirty ? !v$.vendorData.pic.$error : null">
              {{ errorMessages.pic }}
            </b-form-invalid-feedback>
          </div>
        </li>
      </ul>
    </b-col>
    <b-col class="col-6">
      <ul>
        <li>
          <div class="d-block mb-3">
            <label>Alamat</label>
            <b-form-textarea
              v-model="vendorData.alamat"
              type="text"
              :state="v$.vendorData.alamat.$dirty ? !v$.vendorData.alamat.$error : null"
              placeholder="Masukkan Alamat Vendor"
              :rows="5"
            />
            <b-form-invalid-feedback
              :state="v$.vendorData.alamat.$dirty ? !v$.vendorData.alamat.$error : null"
            >
              {{ errorMessages.alamat }}
            </b-form-invalid-feedback>
          </div>
        </li>
        <li>
          <div class="d-block mb-3">
            <label>Deskripsi</label>
            <b-form-textarea
              v-model="vendorData.deskripsi"
              placeholder="Masukkan Deskripsi"
              :rows="5"
            />
          </div>
        </li>
        <b-col class="d-flex justify-content-end mb-3">
          <b-button
            variant="danger-1 mx-2"
            @click="$router.go(-1)"
          >
            Batal
          </b-button>
          <b-button
            variant="success-1 "
            type="submit"
            :loading="isBusy"
            @click="updateVendor"
          >
            <b-spinner
              v-if="isBusy"
              small
              label="Small Spinner"
            />
            <span v-else>Simpan</span>
          </b-button>
        </b-col>
      </ul>
    </b-col>
  </b-row>
</template>

<script>
import { required, minLength } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import {
  BButton,
  BCol,
  BFormInput,
  BFormTextarea,
  BRow,
  BSpinner,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import fieldMappings from './data/fieldMappings'
import handleValidationErrors from '../../../../utils/errors-helpers'

export default {
  setup: () => ({ v$: useVuelidate() }),
  name: 'EditVendor',
  components: {
    BSpinner,
    BButton,
    BCol,
    BRow,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
  },
  data() {
    return {
      vendorData: {
        nama: '',
        alamat: '',
        pic: '',
        telp: '',
        email: '',
        deskripsi: '',
      },
      errorMessages: {},
    }
  },
  validations() {
    return {
      vendorData: {
        nama: { required, minLength: minLength(3) },
        alamat: { required },
        pic: { required },
        telp: { required },
        email: { required },
      },
    }
  },
  computed: {
    isBusy() {
      return this.$store.state.manageVendor.isBusy
    },
    vendor() {
      return this.$store.state.manageVendor.vendor
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('manageVendor/getDetailVendor', this.$route.params.id)

      this.vendorData.nama = this.vendor.name
      this.vendorData.alamat = this.vendor.address
      this.vendorData.pic = this.vendor.pic
      this.vendorData.telp = this.vendor.phone
      this.vendorData.email = this.vendor.email
      this.vendorData.deskripsi = this.vendor.description
    },
    async updateVendor() {
      try {
        const formData = new FormData()

        formData.append('id', this.$route.params.id)
        formData.append('name', this.vendorData.nama)
        formData.append('address', this.vendorData.alamat)
        formData.append('pic', this.vendorData.pic)
        formData.append('phone', this.vendorData.telp)
        formData.append('email', this.vendorData.email)
        formData.append('description', this.vendorData.deskripsi)

        await this.$store.dispatch('manageVendor/updateVendor', formData)

        this.$router.push({ name: 'listvendors' })
        this.$bvToast.toast('Data Vendor Berhasil Di Edit', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      } catch (err) {
        if (err.response && err.response.data && err.response.data.success === false && err.response.data.message) {
          const errorMessage = err.response.data.message
          Object.keys(this.vendorData).forEach(field => {
            if (this.v$.vendorData[field]) {
              this.v$.vendorData[field].$error = true
              this.v$.vendorData[field].$touch()
              this.$set(this.errorMessages, field, fieldMappings[field], errorMessage)
            }
          })
        }
      }
    },
  },
}
</script>

<style>

</style>
